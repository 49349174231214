//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { retrieveButter } from '~/utils/butterUtils'

export default {
  name: 'Voter-Help',
  data () {
    return {
      buttonClass: {
        'is-outlined': true,
        'mb4': true,
        button: true
      },
    }
  },
  async asyncData ({ store }) {
    await retrieveButter(store, 'pages/voter-help asyncData ')
  },
  computed: {
    showTestData () {
      return this.$store.state.showTestData
    },
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    voterColumns () {
      return this.voterHelpData.tile_columns
    },
    voterHelpPage () {
      return this.voterHelpData.vol
    },
    voterHelpData () {
      return this.getVoter(this.butterVoterAssistanceData, this.lang)
    },
    butterVoterAssistanceData () {
      return this.$store.state.butterVoterAssistance
    },
  },
  methods: {
    getVoter(voterAssistanceData, lang) {
      /*
         Reformats the response from an API call to ButterCMS to get collection containing voter assistance items.

       * tile ids consist of one letter followed by an integer
       * tiles are placed in a grid where...
       * the letter identifies the column; the number identifies the row
       * 
       * note: rows are not aligned to appear at the same vertical position on screen
       */
      const months = { 'JAN':'01', 'FEB':'02', 'MAR':'03', 'APR':'04', 'MAY':'05', 'JUN':'06', 'JUL':'07', 'AUG':'08', 'SEP':'09', 'OCT':'10', 'NOV':'11', 'DEC':'12' }

      let tile_ids = []
      let vol = {
        "pageTitle": "",
        "pageInstructions": "",
        "tiles": {},
      }
      const today = new Date().getTime()+parseInt(sessionStorage.getItem("timeWarpOffset"))

      const n_tiles = voterAssistanceData.length
      let ii = 0
      while (ii<n_tiles) {
        const tt = voterAssistanceData[ii] // tile ii

        let dateStart = tt["tile_datetime_start"] ? new Date(tt["tile_datetime_start"]).getTime() : today-1
        let dateEnd   = tt["tile_datetime_end"]   ? new Date(tt["tile_datetime_end"]).getTime()   : today+1
        if (!(dateStart<=today && today<=dateEnd)) {
          ii++
          continue
        }

        const tile_id = tt.id.toUpperCase()

        tile_ids.push(tile_id)

        vol.pageTitle = tt["page_title_"+lang] ? tt["page_title_"+lang] : vol.pageTitle
        vol.pageInstructions = tt["page_instructions_"+lang] ? tt["page_instructions_"+lang] : vol.pageInstructions

        vol.tiles[tile_id] = {}
        vol.tiles[tile_id].instructions = tt["tile_instructions_"+lang]
        vol.tiles[tile_id].datetime_start = tt["tile_datetime_start"]
        vol.tiles[tile_id].datetime_end = tt["tile_datetime_end"]
        vol.tiles[tile_id].image = tt["tile_image"]
        vol.tiles[tile_id].label = tt["tile_label_"+lang]
        vol.tiles[tile_id].links = []
        vol.tiles[tile_id].linkIconDefault = tt["tile_link_icon_default"]
        const link_icon_default = vol.tiles[tile_id].linkIconDefault

        // construct the array of links belonging to the tile
        let jj = 1
        while (true) {
          try {
             //the links are "indexed" in the Butter collection with two-character string representations of the integers starting at 01
            let linkindex = parseInt(jj)
            if (jj<10) {
              linkindex = '0'+linkindex
            }

            const linklabel = tt["link_label_"+linkindex+"_"+lang].trim()
            const link_icon = tt["link_icon_"+linkindex]
            if (linklabel || link_icon) {
              let dateStart = tt["link_datetime_start_"+linkindex] ? new Date(tt["link_datetime_start_"+linkindex]).getTime() : today-1
              let dateEnd   = tt["link_datetime_end_"+linkindex]   ? new Date(tt["link_datetime_end_"+linkindex]).getTime()   : today+1
              if (!(dateStart<=today && today<=dateEnd)) {
                jj++
                continue
              }

              let link = {}
              link.label = linklabel
              link.datetime_start = tt["link_datetime_start_"+linkindex]
              link.datetime_end = tt["link_datetime_end_"+linkindex]
              link.icon = link_icon ? link_icon : (link_icon_default ? link_icon_default : "")
              link.url = tt["link_url_"+linkindex]

              // create a sort key using the date and time embedded in the link label; use the English version of the label
              const linklabelenus = tt["link_label_"+linkindex+"_enus"].trim()
              const daytim = linklabelenus.toUpperCase().replace(/\s+/g, ' ').trim().split(':')
              const dayday = daytim[0].trim().split(' ') // dayName MMM dd

              if ( 'SUN MON TUE WED THU FRI SAT'.includes(dayday[0].slice(0,3))
                && 'JAN FEB MAR APR MAY JUN JUL AUG SEP OCT NOV DEC'.includes(dayday[1].slice(0,3))) {
                // assume we have a real date label from which we can create a sort key
                const daykey = months[dayday[1].slice(0,3)]+('0'+dayday[2]).slice(-2)

                const timtim = daytim[1].trim().split('-') // startHour endHour/meridian/timezone
                let startHour = '0'+timtim[0].trim()
                let endHour = timtim[1].trim() // we don't need to extract the actual hour
                if (startHour.includes('MIDNIGHT')) {
                  startHour = '00'
                } else {
                  if (startHour.includes('AM')) {
                    const ss = startHour.replace('AM','').trim()
                    if (ss!=='12') {
                      startHour = ss.slice(-2)
                    } else {
                      startHour = '00'
                    }
                  } else {
                    if (startHour.includes('NOON')) {
                      startHour = '12'
                    } else {
                      if (startHour.includes('PM')) {
                        startHour = startHour.replace('PM','').trim().slice(-2)
                        const hh = parseInt(startHour) + 12
                        const ss = hh.toString()
                        if (ss!==12) {
                          startHour = ss.slice(-2)
                        } else {
                          startHour = '12'
                        }
                      } else {
                        // no meridian from startHour; we use meridian info from the endHour
                        if (endHour.includes('MIDNIGHT') || endHour.includes('PM')) {
                          // assume startHour is afternoon
                          const hh = parseInt(startHour) + 12
                          const ss = hh.toString()
                          if (ss!==12) {
                            startHour = ss.slice(-2)
                          } else {
                            startHour = '12'
                          }
                        } else {
                          startHour = startHour.slice(-2)
                        }
                      }
                    }
                  }
                }
                
                const timkey = startHour
                link.sortkey = daykey+timkey
              } else {
                link.sortkey = '00' // a placeholder value
              }

              vol.tiles[tile_id].links.push(link)
            }
          } catch (error) {
            break
          }

          jj++
        }

        vol.tiles[tile_id].links.sort((a,b)=>{return (a.sortkey!=b.sortkey)?((a.sortkey<b.sortkey)?-1:1):0})
        ii++
      }

      //construct the matrix of vectors which contain the tile ids for one column
      let tile_columns = {}
      tile_ids.sort()
      for (let k=0; k<tile_ids.length; k++) {
        let tile_id_k = tile_ids[k]
        let letter = tile_id_k.charAt(0)

        if (!Object.keys(tile_columns).includes(letter)) {
          tile_columns[letter] = []
        }
        tile_columns[letter].push(tile_id_k)
      }

      return { tile_columns, vol }
    },
  },
}
